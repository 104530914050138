
export var PurchaseData= {
    value: 'PurchaseData',
    label: 'Purchase Org. Data',
    type: 'standard',
     tabData: [ 
      {
        label: 'Purchase Group',
        type: 'text',
        model: 'purchaseGroup ',
        required: true,
        size:4
        
      },
      {
        label: 'Standard Quality',
        type: 'text',
        model: 'standardQuality',
        size:4
      },
      {
        label: 'Planned Delivery Time',
        type: 'text',
        model: 'plannedDelivery',
        size:4
      },
      {
        label: 'Over Delivery Tolerance',
        type: 'text',
        model: 'overDelTolerance',
        size:4
      },
      {
        label: 'Under Delivery Tolerance',
        type: 'text',
        model: 'underDelTolerance',
        size:4
      },
      {
        label: 'Unlimited Over Delivery',
        type: 'text',
        model: 'unlimitedDelivery',
        size:4
      },
      {
        label: 'Net Price ',
        type: 'text',
        model: 'netPrice',
        required: true,
        size:4
      },
      {
        label: 'Currency ',
        type: 'text',
        model: 'currency',
        required: true,
        size:4
      },
      {
        label: 'Price Determination control',
        type: 'text',
        model: 'priceDeterminationControl',
        size:4
      },
      {
        label: 'Tax Code',
        type: 'text',
        model: 'taxCode',
        size:4
      },
      {
        label: 'Shipping Instructions',
        type: 'text',
        model: 'shippingInstructions',
        size:4
      },
      {
        label: 'Confirmation Control ',
        type: 'text',
        model: 'confirmationControl',
        size:4
      },
      {
        label: 'Incoterm  ',
        type: 'text',
        model: 'Incoterm',
        size:4
      },
      {
        label: 'Incoterm Location 1',
        type: 'text',
        model: 'incotermLocation1',
        size:4
      },
      {
        label: 'Incoterm Location 2',
        type: 'text',
        model: 'incotermLocation2',
        size:4
      },
    ],
   
  };
  