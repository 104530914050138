
export var initial = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
     tabData: [ 
      {
        label: 'Vendor',
        type: 'text',
        model: 'Vendor',
        required: true,
        size:4
        
      },
      {
        label: 'Article ',
        type: 'text',
        model: 'Article',
        required: true,
        size:4
      },
      {
        label: 'Purchase Org.',
        type: 'text',
        model: 'PurchaseOrg',
        required: true,
        size:4
      },
      {
        label: 'Plant',
        type: 'text',
        model: 'plant',
        required: true,
        size:4
      },
    ],
    // validations: [{
    //   fields: ['material'],
    //   keys: ['Matnr'],
    //   source: 'duplicateMaterialNo',
    //   model: 'Material',
    //   onSelect: ['material'],
    //   size: 4
    // }],
  };
  