export var htsForm = {
    value: 'HTS',
    label: 'HTS',
    type: 'multiline',
    model: 'HTS',
    data: [],
    tabData: [
        {
            label: 'Country of Origin',
            type: 'select',
            method: 'api',
            model: 'pim_country',
            source: 'pimcountryKey',
            data: [],
            fieldValues: ['Land1', 'Landx'],
            takeValue: 'Land1',
            showValues: ['Land1', 'Landx'],
            size: 4,
          },
          {
            label: 'HTS search keyword',
            type: 'text',
            // visibleTab: ['corruption'],
            // visibleTabValue: null,
            // visibleSegment: 'questionaire',
            // method: 'api',
            source: 'pimproductMaster',
            model: 'pim_hts_search',
            reloadFields: ['pim_htsNo'],
            reloadParam: 'Maktg',
            data: [],
            required: true,
            fieldValues: ['Maktg'],
            takeValue: 'Matnr',
            showValues: ['Maktg'],
            size: 4,
          },
          {
            label: 'HTS Code',
            type: 'selectMulti',
            method: 'api',
            source: 'pimhtsNo',
            model: 'pim_htsNo',
            data: [],
            fieldValues: ['htsno', 'description'],
            takeValue: 'htsno',
            showValues: ['htsno', 'description'],
            size: 4,
          }
    ]
  };
  